import "core-js/stable";
import "regenerator-runtime/runtime";

console.log("Widget loading...");

// Enable if you need firebase
// import "@/util/firebase";
import widget from "./widget";
import {resolveTargetElement} from "./snippet-util";


import { initRaygun } from "@busy-human/raygun-util";
if(!CONFIG.raygun.apiKey) {
    console.warn("raygun apikey not set");
} else {
    initRaygun(CONFIG.raygun.apiKey, { Vue: widget, version: VERSION, environment: ENVIRONMENT});
}

widget.mount( resolveTargetElement() );
