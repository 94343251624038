import { WIDGET_NAME } from "./util/globals";

/** @self refers to the immediate parent of the script tag, technically creates a child element to drop into */
const SELF_SELECTOR = "@self";

const DEFAULT_SELECTOR = SELF_SELECTOR;
const SETTINGS_ROOT_ATTR = "bhw-settings";

let targetElementSelector: string;
let targetElement: HTMLElement;
let widgetSettingsElement: HTMLElement;

export const INSTANCE_ID = Math.random().toString(36).substring(2, 9);

export const WIDGET_LOG_PREFIX = `[widget:${WIDGET_NAME}:${INSTANCE_ID}]`;

export function log(message: string, ...args: any[]) {
    console.log(`%c${WIDGET_LOG_PREFIX} ` + `%c${message}`,"color:rgb(210 109 49);","color:unset;", ...args);
}

/**
 * Special Widget Error class that gives widget context
 */
export class WidgetError extends Error {
    constructor(message: string) {
        message = `[widget:${WIDGET_NAME}] ${message}`;
        super(message);
        this.name = "WidgetError";
    }
}

/**
 * Get a widget setting off of the snippet element
 * This allows the implementer to customize the widget
 * or provide certain values (e.g. an account id)
 * @param propertyName
 * @param defaultValue
 */
export function getWidgetSetting(propertyName: string, defaultValue: string) {
    if(!widgetSettingsElement) {
        throw new WidgetError(`getWidgetSetting should not be called before resolveTargetElement, or widgetSettingsElement could not be found`);
    }
    return widgetSettingsElement.getAttribute(propertyName) || defaultValue;
}

function resolveWidgetSettingsElement() {
    if(!widgetSettingsElement) {
        if(! document.currentScript?.parentElement ) {
            throw new WidgetError(`The widget script tag must have a parent element`);
        }
        widgetSettingsElement = document.currentScript.parentElement;

        if( ! widgetSettingsElement.hasAttribute(SETTINGS_ROOT_ATTR)) {
            throw new WidgetError(`The widget wrapper element tag in the embedded snippet must have an attribute named ${SETTINGS_ROOT_ATTR}`);
        }
    }

    return widgetSettingsElement;
}

/** Test for one or more settings to be present */
export function expectSettings(propertyNames: string | string[]) {
    if(typeof propertyNames === "string") {
        propertyNames = [propertyNames];
    }
    if(!widgetSettingsElement) {
        throw new WidgetError(`${WIDGET_LOG_PREFIX}getWidgetSetting should not be called before resolveTargetElement, or widgetSettingsElement could not be found`);
    }
    for(let i = 0; i < propertyNames.length; i++) {
        if(!widgetSettingsElement.hasAttribute(propertyNames[i])) {
            throw new WidgetError(`expected setting: ${propertyNames[i]}`);
        }
    }
}

/** Return all the settings (attributes) on the settings element */
export function getAllSettings() {
    const settings: Record<string, string> = {};
    const widgetSettingsElement = resolveWidgetSettingsElement();
    const attributes = widgetSettingsElement.attributes;
    for(let i = 0; i < attributes.length; i++) {
        const attribute = attributes[i];
        if(attribute.name!== SETTINGS_ROOT_ATTR) {
            settings[attribute.name] = attribute.value;
        }
    }
    return settings;
}

/**
 * The target element is the immediate parent of the script tag this is running inside of
 */
function findTargetElement() {
    let element, targetElementSelector = getWidgetSetting("target-element", DEFAULT_SELECTOR);

    if(targetElementSelector === SELF_SELECTOR && widgetSettingsElement) {
        element = document.createElement("div");
        element.setAttribute("is-widget-target-element","true");
        widgetSettingsElement.appendChild(element);
    } else {
        element = document.querySelector(targetElementSelector);
    }

    if(element) {
        targetElement = element as HTMLElement;
        console.log("Mount at target element: ", targetElement);
    } else {
        throw new Error(`Could not find target element ${targetElementSelector}`);
    }
}

export function resolveTargetElement() {
    if(!targetElement) {
        resolveWidgetSettingsElement();
        findTargetElement();
    }
    return targetElement;
}
